<template>
  <v-main style="background: white; height: 100vh">
    <v-container class="pa-5">
      <v-row>
        <v-col>
          <div class="pa-8 mt-10 text-center d-flex align-center flex-column">
            <h1 class="display-1 font-weight-bold my-5 primary--text">ERROR 404</h1>
            <h4 class="primary--text">
              No se ha encontrado la URL solicitada...
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
